/* CSS for dark/light application text */
:root {
  /* Light mode colour scheme */
  --light-background: #d3d3d3;
  --light-text-color: #121212;
  --light-border-color: #a6a6a6;
  --light-typewriter-color: #737373;
  --light-accent-color: #dbedf7;
  --light-accent-opposite-color: #a6a6a6;
  --light-icon-color: #121212;
  --light-circle-color: #dbedf7;

  /* Dark mode colour scheme */
  --dark-background: #121212;
  --dark-text-color: #d3d3d3;
  --dark-border-color: #353535;
  --dark-typewriter-color: #737373;
  --dark-accent-color: #23ec98;
  --dark-accent-opposite-color: #353535;
  --dark-icon-color: #d3d3d3;
  --dark-circle-color: #23ec98;

  /* Typewriter weight */
  --typewriter-font-weight: bold;
}

/* Add spacing between sections */
section {
  margin-bottom: 40px; /* Adjust the margin as needed to separate the sections */
}

.App .hero-container {
  position: relative;
  height: 100vh;
}

.App {
  text-align: center;
  overflow-x: hidden;
}

/* CSS for dark/light application theme */
.App.light {
  background-color: var(--light-background);
  color: var(--light-text-color);
}

.App.dark {
  background-color: var(--dark-background);
  color: var(--dark-text-color);
}

/* Styles for .typewriter based on dark mode */
.App.dark .typewriter {
  color: var(--dark-typewriter-color);
  font-weight: var(--typewriter-font-weight);
  text-align: var(--typewriter-text-align);
}

/* Styles for .typewriter based on light mode */
.App.light .typewriter {
  color: var(--light-typewriter-color);
  font-weight: var(--typewriter-font-weight);
  text-align: var(--typewriter-text-align);
}

/* CSS for HERO page */
.App .hero {
  display: flex;
  align-items: center;
  height: 100vh;
}

.App .hero-text h1 {
  bottom: 10vh;
  font-size: 3em;
  font-weight: bold;
  width: 100vw;
}

.App .hero-text p1 {
  display: none;
  font-size: 1em;
  font-weight: bold;
}

.metamask-logo {
  position: absolute;
  top: 10px; /* Adjust the top position as needed */
  right: 10px; /* Adjust the right position as needed */
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  cursor: pointer;
}

.App .herofooter {
  position: relative;
  bottom: 5vh;
  margin-left: 3vw;
  /* Add a left margin of 3vw */
  margin-right: 3vw;
  /* Add a right margin of 3vw */
  margin: 15 auto;
  /* Center-align the element */
  font-weight: bold;
  font-size: 0.75em;
  overflow: hidden;
  text-align: center;
  /* Center-align the text within the container */
}

/* Dark mode */
.App.dark .hero-image {
  background-image: url("./assets/images/dark-mount.jpeg");
}

/* Light mode */
.App.light .hero-image {
  background-image: url("./assets/images/hero-mount2.jpeg");
}

/* Define styles for light theme text */
.App .hero-text-light {
  flex: 1;
}

/* Define styles for dark theme text */
.App .hero-text-dark {
  flex: 1;
}

/* Typewriter CSS */
.App .custom-text-style {
  text-align: center;
  /* Add any other styles you want to apply */
}

.App .typewriter-container {
  position: relative;
  font-size: 3vh;
  overflow: hidden;
  margin: 0 auto;
  /* Horizontally center the typewriter-container */
  max-width: 100%;
  /* Ensure it doesn't exceed the parent's width */
  height: 100px;
  bottom: 5vh;
}

/* CSS for the social media icons */

.App.light .stick_follow_icon {
  color: var(--light-icon-color);
  /* Text color for light mode */
}

.App.light .icon {
  fill: var(--light-icon-color);
}

.App.dark .stick_follow_icon {
  color: var(--dark-icon-color);
  /* Text color for dark mode */
}

.App.dark .icon {
  fill: var(--dark-icon-color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Media queries */

/* Media query for screens with a resolution of "Insert x resolution" and above */
@media (min-width: 1170px) and (min-height: 800px) {
  .App .hero-image {
    flex: 1;
    height: 100%;
    width: 50vw;
    /* Set the width to 50% of the viewport width */
    background-size: cover;
    background-position: right center;
    transition: opacity 0.3s ease;
    /* Add a smooth opacity transition */
  }

  .App .hero-text h1 {
    font-size: 3vw;
    font-weight: bold;
    text-align: center;
    width: 50vw;
  }

  .App .hero-text p1 {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }

  .App .herofooter {
    position: absolute;
    bottom: 10vh;
    left: 0;
    right: 0;
    /* Set both left and right to 0 to span the whole viewport width */
    text-align: center;
    font-weight: bold;
    font-size: 1.4em;
  }

  /* Typewriter Media Queries */
  .App .custom-text-style {
    text-align: left;
  }

  .App .typewriter-container {
    margin-left: 10vw;
    height: 150px;
    font-size: 4vh;
    overflow: hidden;
  }
}

/* Largest screens */
@media (min-width: 2000px) and (min-height: 1250px) {
  .App .hero-text p1 {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }

  .App .herofooter {
    position: absolute;
    bottom: 10vh;
    left: 0;
    right: 0;
    /* Set both left and right to 0 to span the whole viewport width */
    text-align: center;
    font-weight: bold;
    font-size: 2.2em;
  }

  /* Typewriter Media Queries */
  .App .custom-text-style {
    text-align: left;
  }

  .App .typewriter-container {
    margin-left: 10vw;
    height: 200px;
    font-size: 2vw;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
  }
}
