/* General Page Styles */
.timeline-page {
  font-family: Arial, sans-serif; /* General font for the page */
  color: #fff; /* Default text color */
  text-align: center; /* Center header and subheader text */
  padding: 20px; /* Add some padding to the top of the page */
}

/* Header Section */
.timeline-header {
  margin-bottom: 30px; /* Space between the header and the timeline */
}

.timeline-header h1 {
  font-size: 2.5em;
  margin: 0;
}

.timeline-header .timeline-subheader {
  font-size: 1.2em;
  margin: 10px 0 0;
  color: #aaa; /* Subheader in a lighter color */
}

/* General Container */
.timeline-container {
  position: relative;
  width: 80%; /* Adjust width as needed */
  height: 80vh; /* Set height to occupy 80% of the viewport */
  margin: 0 auto; /* Center horizontally */
  overflow-x: auto; /* Allow horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling */
  white-space: nowrap; /* Prevent wrapping */
  padding: 0; /* Remove overall padding */
  box-sizing: border-box; /* Ensure padding is included in width/height calculations */
}

/* Horizontal Line */
.timeline-bar {
  position: relative;
  top: 50%; /* Center the timeline bar vertically */
  left: 10vh; /* Shift bar left to add space for start point */
  width: calc(100% - 20vh); /* Reduce width to allow space for end point */
  height: 4px;
  background-color: #ddd;
}

/* Timeline Events */
.timeline-event {
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  white-space: nowrap; /* Prevent text wrapping */
}

/* Dot Style */
.timeline-dot {
  width: 10px;
  height: 10px;
  background-color: #4285f4;
  border-radius: 50%;
  margin: 0 auto;
}

/* Year Style */
.timeline-year {
  margin-top: 5px;
  font-size: 0.8em;
  color: #333;
}

/* Current Year Style */
.timeline-event.current .timeline-dot {
  background-color: #34a853; /* Highlight current year in green */
  width: 12px;
  height: 12px;
}
.timeline-event.current .timeline-year {
  font-weight: bold;
  color: #34a853;
}

/* Event Label (Optional) */
.timeline-label {
  margin-top: 5px;
  font-size: 0.75em;
  color: #555;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .timeline-container {
    height: 100vh; /* Adjust height for smaller screens */
  }

  .timeline-year {
    font-size: 0.7em;
  }

  .timeline-header h1 {
    font-size: 2em;
  }

  .timeline-header .timeline-subheader {
    font-size: 1em;
  }
}
